import React, { useEffect, useMemo, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { meshGatewayClient } from 'src/apollo/client';
import Breadcrumbs from 'src/components/breadcrumbs/breadcrumbs';
import { GET_SUSPENSION, LIST_SUSPENSIONS_FOR_PLAYER, UPDATE_SUSPENSION } from 'src/components/players/players-queries';
import Spinner from 'src/components/spinner/spinner';
import SuspensionForm from 'src/components/suspension-form/suspension-form';
import { H2 } from 'src/components/typography/typography';
import { usePlayerByExternalId } from 'src/hooks/data/suspensions';
import { NavigationState } from 'src/utils/typedefs/navigation';

interface Props {
  id: string;
  suspensionId: string;
}

const EditSuspension: React.FC<Props> = ({ id, suspensionId }) => {
  const { t } = useTranslation();
  const { fullName, loading: loadingPlayer } = usePlayerByExternalId(id);

  const { loading: loadingSuspension, data: suspensions } = useQuery(LIST_SUSPENSIONS_FOR_PLAYER, {
    client: meshGatewayClient,
    fetchPolicy: 'no-cache',
    variables: { externalId: id },
  });

  const { data: suspension } = useQuery(GET_SUSPENSION, {
    client: meshGatewayClient,
    variables: { id: suspensionId },
    fetchPolicy: 'no-cache',
    skip: !suspensionId,
  });

  const [editSuspension, { loading }] = useMutation(UPDATE_SUSPENSION, {
    client: meshGatewayClient,
  });

  const suspensionData = useMemo(() => {
    if (!suspension) {
      const suspensionList = suspensions?.listSuspensionsForPlayer;
      return suspensionList?.[suspensionList?.length - 1];
    }

    return suspension.getSuspension;
  }, [suspension, suspensions]);

  const [selectedViolations, setSelectedViolations] = useState<any>([]);

  const editSelectedPoints = (id, checked) => {
    let selected = selectedViolations;
    if (checked === true) {
      selected.push(id);
    } else {
      selected = selected.filter((i) => i !== id);
    }
    setSelectedViolations(selected);
  };

  const [formVals, setFormVals] = useState<any>(undefined);

  useEffect(() => {
    if (suspensionData) {
      const { startDate, endDate, comment } = suspensionData;
      setFormVals({
        startDate: startDate,
        endDate: endDate,
        comment: comment,
      });
    }
  }, [suspensionData]);

  const handleSubmit = (values) => {
    editSuspension({
      variables: {
        suspensionId: suspensionId ?? suspensionData.suspensionId,
        input: values,
      },
      onCompleted: () => {
        const state: NavigationState = {
          popup: {
            message: t('suspension updated'),
          },
        };
        navigate(`/players/${id}?tab=suspensions`, { state });
      },
    });
  };

  return loadingPlayer ? (
    <Spinner />
  ) : (
    <>
      <Breadcrumbs
        paths={[
          { name: t('players'), to: '/players' },
          { name: fullName, to: `/players/${id}?tab=suspensions` },
          { name: t('edit suspension'), active: true },
        ]}
      />
      <H2 spacing={{ margins: { sm: 'top' } }}>{t('edit suspension')}</H2>
      {loadingSuspension && <Spinner />}
      {!loadingSuspension && formVals && (
        <SuspensionForm
          id={id}
          suspensionId={suspensionData?.suspensionId}
          handleSubmit={handleSubmit}
          loading={loading}
          formVals={formVals}
          editSelectedPoints={editSelectedPoints}
        />
      )}
    </>
  );
};

export default EditSuspension;
