import React from 'react';

import { RouteComponentProps, Router } from '@reach/router';
import EditSuspension from 'src/components/edit-suspension/edit-suspension';
import Layout from 'src/components/layout/layout';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SEO from 'src/components/seo';

const EditSuspensionPage = () => {
  return (
    <Router basepath="/players/:id/suspensions">
      <EditSuspensionRoute path="/:suspensionId" />
    </Router>
  );
};

interface EditSuspensionRouteProps extends RouteComponentProps {
  id: string;
  suspensionId: string;
}

const EditSuspensionRoute: React.FC<EditSuspensionRouteProps> = ({ id, suspensionId }) => {
  return (
    <Layout>
      <SEO title="Edit violation" />
      <PrivateRoute>
        <EditSuspension id={id} suspensionId={suspensionId} />
      </PrivateRoute>
    </Layout>
  );
};

export default EditSuspensionPage;
